body {
  margin: 0;
  padding: 0;
  font-family: "Verdana, Geneva, sans-serif";
}

.app-container {
  position: relative;
  text-align: center;
  max-width: 1280px;
  margin: auto;
  color: #333;
  background-color: #f4f4f4;
  padding: 12px;
}

.app-navigation ul {
  position: absolute;
  top: 0;
  right: 32px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  list-style: none;
}

.app-navigation .dropdown {
  position: relative;
  display: inline-block;
}

.app-navigation .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.app-navigation .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.app-navigation .dropdown-content a:hover {
  background-color: #f1f1f1;
}

.app-navigation .dropdown:hover .dropdown-content {
  display: block;
}

.app-navigation a,
.app-navigation a:visited {
  text-decoration: none;
  color: #555;
}

.app-navigation a:hover {
  color: #1868b3;
}

.app-title {
  font-size: 24px;
  margin-top: 0;
}

.page-title {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 16px;
}

.input {
  padding: 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px 0 0 4px;
  margin-bottom: 20px;
}

.search-input {
  text-align: center;
  width: 50%;
}

.endpoint-input {
  width: 90%;
}

.total-labels-text {
  color: #555;
  font-size: 16px;
  margin: 0 auto 12px auto;
}

.labels-container {
  display: flex;
  margin-bottom: 20px;
}

.labels {
  padding: 0 24px 12px 24px;
  width: 100%;
}

.labels:first-child {
  border-right: 2px solid #ddd;
}

.labels .title {
  font-size: 18px;
  margin-right: auto;
  margin-left: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-around;
  gap: 16px 10%;
  padding: 0 5%;
}

.label-item {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  font-size: 16px;
  font-family: "Verdana, Geneva, sans-serif";
  cursor: pointer;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pagination-button.active {
  background-color: #4caf50;
  color: white;
}

.show-more-button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px 32px;
  border-radius: 8px;
  position: relative;
}

.matched-queries-list li {
  margin-bottom: 6px;
}

.close-modal-btn {
  margin-top: 8px;
  padding: 8px 16px;
  cursor: pointer;
}

.error-msg {
  color: red;
  font-size: 18;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid steelblue;
  border-bottom-color: lightblue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: auto;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
